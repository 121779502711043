// import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import { Button, Card, Icon, Image, Transition } from 'semantic-ui-react';

function App() {

  const [ visible, setVisible ] = useState(false);

  const toggleVisible = () => setVisible(prevState => !prevState);

  return (
    <div className="App">
        <Transition visible={visible} animation='horizontal flip' duration={500}>
          <Card centered visible={true}>
          <Image src='/images/avatar/large/matthew.png' wrapped ui={false} />
          <Card.Content>
            <Card.Header>Matthew</Card.Header>
            <Card.Meta>
              <span className='date'>Joined in 2015</span>
            </Card.Meta>
            <Card.Description>
              Matthew is a musician living in Nashville.
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Icon name='user' />
              22 Friends
            </a>
          </Card.Content>
          </Card>
        </Transition>
        <Button onClick={toggleVisible}>카드 회전</Button>
    </div>
  );
}

export default App;
